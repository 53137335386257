import {Section} from "../services/Section";
import UserData from "../UserData";

export interface WhitelabelEvent {
    type: string;
}

export interface WhitelabelEventWithPayload<T> extends WhitelabelEvent {
    payload: T;
}

export class PageLoadedEvent<T> implements WhitelabelEventWithPayload<T> {
    type: string;
    payload: T;
    static readonly TYPE: string = 'wl-page-loaded';

    constructor(payload: T) {
        this.type = PageLoadedEvent.TYPE;
        this.payload = payload;
    }
}

export interface CheckboxUniqueSelectClickEventPayload {
    inputName: string;
    inputValue: string;
    inputChecked: boolean;
    inputText: string;
    //dataName: string;
}

export class CheckboxUniqueSelectClickEvent implements WhitelabelEventWithPayload<CheckboxUniqueSelectClickEventPayload> {
    payload: CheckboxUniqueSelectClickEventPayload;
    type: string;
    static readonly TYPE: string = 'wl-linear-selector-filter-input-click';

    constructor(payload: CheckboxUniqueSelectClickEventPayload) {
        this.type = CheckboxUniqueSelectClickEvent.TYPE;
        this.payload = payload;
    }
}

export interface LinearSelectorClickEventPayload {
    inputName: string;
    inputValue: string;
    inputChecked: boolean;
}

export class LinearSelectorClickEvent implements WhitelabelEventWithPayload<LinearSelectorClickEventPayload> {
    payload: LinearSelectorClickEventPayload;
    type: string;
    static readonly TYPE: string = 'wl-linear-selector-filter-input-click';

    constructor(payload: LinearSelectorClickEventPayload) {
        this.type = LinearSelectorClickEvent.TYPE;
        this.payload = payload;
    }
}

export interface SelectChangeEventPayload {
    id: string;
    name: string;
    value: string;
}

export class SelectChangeEvent implements WhitelabelEventWithPayload<SelectChangeEventPayload> {
    payload: SelectChangeEventPayload;
    type: string;
    static readonly TYPE: string = 'wl-select-change';

    constructor(payload: SelectChangeEventPayload) {
        this.type = SelectChangeEvent.TYPE;
        this.payload = payload;
    }
}

export interface SelectorContainerFilterApplyClickEventPayload {
    dataName: string;
}

export class SelectorContainerFilterButtonClickEvent implements WhitelabelEventWithPayload<SelectorContainerFilterApplyClickEventPayload> {
    payload: SelectorContainerFilterApplyClickEventPayload;
    type: string;
    static readonly TYPE: string = 'wl-selector-container-filter-click';

    constructor(payload: SelectorContainerFilterApplyClickEventPayload) {
        this.type = SelectorContainerFilterButtonClickEvent.TYPE;
        this.payload = payload;
    }
}

export interface DropdownElementClickPayload {
    dropdownId: string,
    dataValues: any,
    content: string,
    element: Element,
    event: Event,
}

export class DropdownElementClickEvent implements WhitelabelEventWithPayload<DropdownElementClickPayload> {
    payload: DropdownElementClickPayload;
    type: string;
    static readonly TYPE: string = 'wl-dropdown-element-click';

    constructor(payload: DropdownElementClickPayload) {
        this.type = DropdownElementClickEvent.TYPE;
        this.payload = payload;
    }
}

// whitelabel-core/core/src/main/kotlin/com/lifullconnect/whitelabel/core/Geo.kt
export interface ApiGeoResponse {
    id: number;
    name: string;
    url_name: string;
    parent_names: string[];
}

export interface GenerateTextSuggestionElementData {
    id: string;
    name: string;
    completeText: string;
    isProject?: boolean;
}

export interface AutocompleteResponsePayload {
    body: ApiGeoResponse[],
    callbacks: {
        generateTextSuggestionElement: (html: string, data: GenerateTextSuggestionElementData) => void,
        insertAdjacentHTML: (html: string) => void,
        showAutocompleteDataList: () => void,
    },
    cssClassNames: {
        textSuggestionCssClass: string;
        textSuggestionTitleCssClass: string;
        focusCssClass: string;
    }
}

export class AutocompleteResponseEvent implements WhitelabelEventWithPayload<AutocompleteResponsePayload> {
    payload: AutocompleteResponsePayload;
    type: string;
    static readonly TYPE: string = 'wl-autocomplete-response';

    constructor(payload: AutocompleteResponsePayload) {
        this.type = AutocompleteResponseEvent.TYPE;
        this.payload = payload;
    }
}

export interface GalleryPhotoClickedPayload {
    slideActive: number;
}

export class GalleryPhotoClickedEvent implements WhitelabelEventWithPayload<GalleryPhotoClickedPayload> {
    type: string;
    static readonly TYPE: string = 'wl-gallery-photo-clicked';
    payload: GalleryPhotoClickedPayload;

    constructor(payload: GalleryPhotoClickedPayload) {
        this.type = GalleryPhotoClickedEvent.TYPE;
        this.payload = payload;
    }
}

export class GalleryFullScreenGalleryOpenedEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-fullscreen-gallery-opened';

    constructor() {
        this.type = GalleryFullScreenGalleryOpenedEvent.TYPE;
    }
}

export class GalleryFullScreenGalleryClosedEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-fullscreen-gallery-closed';

    constructor() {
        this.type = GalleryFullScreenGalleryClosedEvent.TYPE;
    }
}

export class GalleryReloadNavigationEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-gallery-reload-navigation';
    payload: {};

    constructor(payload: {}) {
        this.type = GalleryReloadNavigationEvent.TYPE;
        this.payload = payload;
    }
}

export interface ListingHighlightClickPayload {
    id: string;
}

export class ListingHighlightClickEvent implements WhitelabelEventWithPayload<ListingHighlightClickPayload> {
    type: string;
    static readonly TYPE: string = 'wl-listing-highlight-click';
    payload: ListingHighlightClickPayload;

    constructor(payload: ListingHighlightClickPayload) {
        this.type = ListingHighlightClickEvent.TYPE;
        this.payload = payload;
    }
}

export interface AppliedFormEventPayload {
    name: string;
    email: string;
    phone: string;
}

export class AppliedFormEvent implements WhitelabelEventWithPayload<AppliedFormEventPayload> {
    type: string;
    static readonly TYPE: string = 'wl-applied-form';
    payload: AppliedFormEventPayload;

    constructor(payload: AppliedFormEventPayload) {
        this.type = AppliedFormEvent.TYPE;
        this.payload = payload;
    }
}

export interface MultiEnquiryPhoneListingsImpressionEventPayload {
    pageViewId: string;
    listingIds: string[];
    section: Section;
    step: number;
}

export class MultiEnquiryPhoneListingsImpressionEvent implements WhitelabelEventWithPayload<MultiEnquiryPhoneListingsImpressionEventPayload> {
    type: string;
    static readonly TYPE: string = 'wl-multi-enquiry-phone-listings-impression';
    payload: MultiEnquiryPhoneListingsImpressionEventPayload;

    constructor(payload: MultiEnquiryPhoneListingsImpressionEventPayload) {
        this.type = MultiEnquiryPhoneListingsImpressionEvent.TYPE;
        this.payload = payload;
    }
}

export interface FullPageModalEventPayload {
    id: string;
}

export class FullPageModalOpenEvent implements WhitelabelEventWithPayload<FullPageModalEventPayload> {
    type: string;
    payload: FullPageModalEventPayload;
    static readonly TYPE: string = 'wl-fullpage-modal-open';

    constructor(payload: FullPageModalEventPayload) {
        this.type = FullPageModalOpenEvent.TYPE;
        this.payload = payload;
    }
}

export interface ModalOpenedEventPayload {
    element?: HTMLElement;
}

export class ModalOpenedEvent implements WhitelabelEventWithPayload<ModalOpenedEventPayload> {
    type: string;
    payload: ModalOpenedEventPayload;
    static readonly TYPE: string = 'wl-modal-opened';

    constructor(payload: ModalOpenedEventPayload) {
        this.type = ModalOpenedEvent.TYPE;
        this.payload = payload;
    }
}

export class FullPageModalOpenedEvent implements WhitelabelEventWithPayload<FullPageModalEventPayload> {
    type: string;
    payload: FullPageModalEventPayload;
    static readonly TYPE: string = 'wl-fullpage-modal-opened';

    constructor(payload: FullPageModalEventPayload) {
        this.type = FullPageModalOpenedEvent.TYPE;
        this.payload = payload;
    }
}

export class FullPageModalCloseEvent implements WhitelabelEventWithPayload<FullPageModalEventPayload> {
    type: string;
    payload: FullPageModalEventPayload;
    static readonly TYPE: string = 'wl-fullpage-modal-close';

    constructor(payload: FullPageModalEventPayload) {
        this.type = FullPageModalCloseEvent.TYPE;
        this.payload = payload;
    }
}

export class FullPageModalClosedEvent implements WhitelabelEventWithPayload<FullPageModalEventPayload> {
    type: string;
    payload: FullPageModalEventPayload;
    static readonly TYPE: string = 'wl-fullpage-modal-closed';

    constructor(payload: FullPageModalEventPayload) {
        this.type = FullPageModalClosedEvent.TYPE;
        this.payload = payload;
    }
}

export interface ObfuscatedElementClickedEventPayload {
    section: Section;
    propertyAdId: string;
    pageViewId: string;
    step: number;
    htmlButtonClicked: HTMLElement;
    pageViewType: string;
}

export class ObfuscatedElementClickedEvent implements WhitelabelEventWithPayload<ObfuscatedElementClickedEventPayload> {
    type: string;
    payload: ObfuscatedElementClickedEventPayload;
    static readonly TYPE: string = 'wl-obfuscated-element-clicked';

    constructor(payload: ObfuscatedElementClickedEventPayload) {
        this.type = ObfuscatedElementClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface ProjectUnitButtonClickedEventPayload {
    section: Section;
    propertyAdId: string;
    pageViewId: string;
    step: number;
    pageViewType: string;
    showOperationSelector: boolean;
    showRentDatePicker: boolean;
    isRent: boolean
}

export class ProjectUnitButtonClickedEvent implements WhitelabelEventWithPayload<ProjectUnitButtonClickedEventPayload> {
    type: string;
    payload: ProjectUnitButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-projectUnit-element-clicked';

    constructor(payload: ProjectUnitButtonClickedEventPayload) {
        this.type = ProjectUnitButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface ContactModalFormDisplayedEventPayload {
    section: Section;
    propertyAdId: string;
    pageViewId: string;
    step: number;
    pageViewType: string;
}

export class ContactModalFormDisplayedEvent implements WhitelabelEventWithPayload<ContactModalFormDisplayedEventPayload> {
    type: string;
    payload: ContactModalFormDisplayedEventPayload;
    static readonly TYPE: string = 'wl-contact-modal-form-displayed';

    constructor(payload: ContactModalFormDisplayedEventPayload) {
        this.type = ContactModalFormDisplayedEvent.TYPE;
        this.payload = payload;
    }
}

export interface AgencyPhoneModalOpenedEventPayload {
    section: Section;
    pageViewId: string;
    propertyAdId: string;
    pageViewType: string;
}

export class AgencyPhoneModalOpenedEvent implements WhitelabelEventWithPayload<AgencyPhoneModalOpenedEventPayload> {
    type: string;
    payload: AgencyPhoneModalOpenedEventPayload;
    static readonly TYPE: string = 'wl-agency-phone-modal-opened';

    constructor(payload: AgencyPhoneModalOpenedEventPayload) {
        this.type = AgencyPhoneModalOpenedEvent.TYPE;
        this.payload = payload;
    }
}

export interface AgencyPhoneButtonClickedEventPayload {
    section: Section;
    pageViewId: string;
    propertyAdId: string;
    pageViewType: string;
}

export class AgencyPhoneButtonClickedEvent implements WhitelabelEventWithPayload<AgencyPhoneButtonClickedEventPayload> {
    type: string;
    payload: AgencyPhoneButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-agency-phone-button-clicked';

    constructor(payload: AgencyPhoneButtonClickedEventPayload) {
        this.type = AgencyPhoneButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface PhoneTrackingModalOpenEventPayload {
    section: Section;
    pageViewId: string;
    propertyAdId: string;
    requestedPhone: string;
    requestedPhoneExtension: string;
    userData: UserData;
}

export class PhoneTrackingModalOpenEvent implements WhitelabelEventWithPayload<PhoneTrackingModalOpenEventPayload> {
    type: string;
    payload: PhoneTrackingModalOpenEventPayload;
    static readonly TYPE: string = 'wl-phone-tracking-modal-open';

    constructor(payload: PhoneTrackingModalOpenEventPayload) {
        this.type = PhoneTrackingModalOpenEvent.TYPE;
        this.payload = payload;
    }
}

export interface ChatButtonClickedEventPayload {
    propertyAdId: string;
    pageViewId: string;
    step: number;
    section: Section;
    htmlButtonClicked: HTMLElement;
    pageViewType: string;
}

export class ChatButtonClickedEvent implements WhitelabelEventWithPayload<ChatButtonClickedEventPayload> {
    type: string;
    payload: ChatButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-chat-button-clicked';

    constructor(payload: ChatButtonClickedEventPayload) {
        this.type = ChatButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface OpenSocialNetworkEventPayload {
    socialNetworkSection: string,
    socialNetworkName: string, /** @see chatSectionNames in src/common/ts/ChatSections.ts */
}

export class OpenSocialNetworkEvent implements WhitelabelEventWithPayload<OpenSocialNetworkEventPayload> {
    type: string;
    payload: OpenSocialNetworkEventPayload;
    static readonly TYPE: string = 'wl-open-social-network';

    constructor(payload: OpenSocialNetworkEventPayload) {
        this.type = OpenSocialNetworkEvent.TYPE;
        this.payload = payload;
    }
}

export interface StickyContactButtonClickedEventPayload {
    step: number;
    section: Section;
    pageViewId: string;
    propertyAdId: string;
    pageViewType: string;
}

export class StickyContactButtonClickedEvent implements WhitelabelEventWithPayload<StickyContactButtonClickedEventPayload> {
    type: string;
    payload: StickyContactButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-sticky-contact-button-clicked';

    constructor(payload: StickyContactButtonClickedEventPayload) {
        this.type = StickyContactButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export class StickyScheduleButtonClickedEvent implements WhitelabelEventWithPayload<StickyContactButtonClickedEventPayload> {
    type: string;
    payload: StickyContactButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-sticky-schedule-button-clicked';

    constructor(payload: StickyContactButtonClickedEventPayload) {
        this.type = StickyScheduleButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface SerpSnippetClickedEventPayload {
    listingId: string;
    isSponsored: boolean;
    listing: Element;
    event: Event;
}

export class SerpSnippetClickedEvent implements WhitelabelEventWithPayload<SerpSnippetClickedEventPayload> {
    type: string;
    payload: SerpSnippetClickedEventPayload;
    static readonly TYPE: string = 'wl-serp-snippet-clicked';

    constructor(payload: SerpSnippetClickedEventPayload) {
        this.type = SerpSnippetClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface SnippetViewExtendedDescriptionClickedEventPayload {
    element: Element;
}

export class SnippetViewExtendedDescriptionClickedEvent implements WhitelabelEventWithPayload<SnippetViewExtendedDescriptionClickedEventPayload> {
    type: string;
    payload: SnippetViewExtendedDescriptionClickedEventPayload;
    static readonly TYPE: string = 'wl-serp-snippet-view-extended-description-clicked';

    constructor(payload: SnippetViewExtendedDescriptionClickedEventPayload) {
        this.type = SnippetViewExtendedDescriptionClickedEvent.TYPE;
        this.payload = payload;
    }
}

export interface FilterButtonClickedEventPayload {
    event: Event;
}

export class FilterButtonClickedEvent implements WhitelabelEventWithPayload<FilterButtonClickedEventPayload> {
    type: string;
    payload: FilterButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-serp-filter-button-clicked';

    constructor(payload: FilterButtonClickedEventPayload) {
        this.type = FilterButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export class SerpListingsUpdatedEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-serp-listings-updated';

    constructor() {
        this.type = SerpListingsUpdatedEvent.TYPE;
    }
}

export interface SerpSnippetContactButtonClickedEventPayload {
    step: number;
    section: Section;
    pageViewId: string;
    listingId: string;
    pageViewType: string;
    showRentDatePicker: boolean;
    showOperationSelector: boolean;
}

export class SerpSnippetContactButtonClickedEvent implements WhitelabelEventWithPayload<SerpSnippetContactButtonClickedEventPayload> {
    type: string;
    payload: SerpSnippetContactButtonClickedEventPayload;
    static readonly TYPE: string = 'wl-serp-snippet-contact-button-clicked';

    constructor(payload: SerpSnippetContactButtonClickedEventPayload) {
        this.type = SerpSnippetContactButtonClickedEvent.TYPE;
        this.payload = payload;
    }
}

export class RefreshSwipeComponentEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-refresh-swipe-component';

    constructor() {
        this.type = RefreshSwipeComponentEvent.TYPE;
    }
}

export class PhoneContactSentEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-phone-Contact-sent';

    constructor() {
        this.type = PhoneContactSentEvent.TYPE;
    }
}

export interface ExtendedDescriptionClickedEventPayload {
    listingId: string;
    isSponsored: boolean;
    listing: Element;
    event: Event;
}

export class ExtendedDescriptionClickedEvent implements WhitelabelEventWithPayload<ExtendedDescriptionClickedEventPayload> {
    type: string;
    payload: ExtendedDescriptionClickedEventPayload;
    static readonly TYPE: string = 'wl-serp-snippet-extended-description-clicked';

    constructor(payload: ExtendedDescriptionClickedEventPayload) {
        this.type = ExtendedDescriptionClickedEvent.TYPE;
        this.payload = payload;
    }
}

export class ShowGoogleOneTapEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-tracking-alert-creation-impression-event';

    constructor() {
        this.type = ShowGoogleOneTapEvent.TYPE;
    }
}

export interface GoogleOneTapEventPayload {
    flow: GoogleOneTapFlowEvent;
}

export enum GoogleOneTapFlowEvent {
    SIGN_IN, CLOSE, NOT_USED
}

export class GoogleOneTapSignInEvent implements WhitelabelEventWithPayload<GoogleOneTapEventPayload> {
    type: string;
    payload: GoogleOneTapEventPayload;
    static readonly TYPE: string = 'wl-google-one-tap-sign-in';

    constructor(payload: GoogleOneTapEventPayload) {
        this.type = GoogleOneTapSignInEvent.TYPE;
        this.payload = payload;
    }
}

export class GoogleOneTapFirstSignInEvent implements WhitelabelEventWithPayload<GoogleOneTapEventPayload> {
    type: string;
    payload: GoogleOneTapEventPayload;
    static readonly TYPE: string = 'wl-google-one-tap-first-sign-in';

    constructor(payload: GoogleOneTapEventPayload) {
        this.type = GoogleOneTapFirstSignInEvent.TYPE;
        this.payload = payload;
    }
}

export class GoogleOneTapCloseEvent implements WhitelabelEventWithPayload<GoogleOneTapEventPayload> {
    type: string;
    payload: GoogleOneTapEventPayload;
    static readonly TYPE: string = 'wl-google-one-tap-close';

    constructor(payload: GoogleOneTapEventPayload) {
        this.type = GoogleOneTapCloseEvent.TYPE;
        this.payload = payload;
    }
}

export interface WebPushNotificationEventPayload {
    flow: GoogleOneTapFlowEvent;
}

export class WebPushNotificationGrantedEvent implements WhitelabelEventWithPayload<WebPushNotificationEventPayload> {
    type: string;
    payload: WebPushNotificationEventPayload;
    static readonly TYPE: string = 'wl-web-push-notification-granted';

    constructor(payload: WebPushNotificationEventPayload) {
        this.type = WebPushNotificationGrantedEvent.TYPE;
        this.payload = payload;
    }
}

export class WebPushNotificationDeniedEvent implements WhitelabelEventWithPayload<WebPushNotificationEventPayload> {
    type: string;
    payload: WebPushNotificationEventPayload;
    static readonly TYPE: string = 'wl-web-push-notification-denied';

    constructor(payload: WebPushNotificationEventPayload) {
        this.type = WebPushNotificationDeniedEvent.TYPE;
        this.payload = payload;
    }
}

export interface PointOfCaptureExitEventPayload {
    flow: GoogleOneTapFlowEvent;
}

export class PointOfCaptureExitEvent implements WhitelabelEventWithPayload<WebPushNotificationEventPayload> {
    type: string;
    payload: PointOfCaptureExitEventPayload;
    static readonly TYPE: string = 'wl-point-of-capture-exit';

    constructor(payload: PointOfCaptureExitEventPayload) {
        this.type = PointOfCaptureExitEvent.TYPE;
        this.payload = payload;
    }
}

export class DidomiNoticeClickAgreeEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-notice-click-agree';

    constructor() {
        this.type = DidomiNoticeClickAgreeEvent.TYPE;
    }
}

export class DidomiNoticeClickDisagreeEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-notice-click-disagree';

    constructor() {
        this.type = DidomiNoticeClickDisagreeEvent.TYPE;
    }
}

export class DidomiClickAgreeToAllEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-click-agree-to-all';

    constructor() {
        this.type = DidomiNoticeClickDisagreeEvent.TYPE;
    }
}

export class ConsentCookieIsAlreadySetEvent implements WhitelabelEvent {
    type: string;
    static readonly TYPE: string = 'wl-consent-cookie-is-already-set';

    constructor() {
        this.type = ConsentCookieIsAlreadySetEvent.TYPE;
    }
}

export interface UserHasNotInteractedEventPayload {
    flow: GoogleOneTapFlowEvent;
}

export class UserHasNotInteractedEvent implements WhitelabelEventWithPayload<WebPushNotificationEventPayload> {
    type: string;
    payload: UserHasNotInteractedEventPayload;
    static readonly TYPE: string = 'wl-user-has-not-interacted';

    constructor(payload: UserHasNotInteractedEventPayload) {
        this.type = UserHasNotInteractedEvent.TYPE;
        this.payload = payload;
    }
}

export interface FormFieldsStoredEventPayload {
    dummy?: 'this is temporary to fix build (reason: error  An empty interface is equivalent to `{}`  @typescript-eslint/no-empty-interface)' // FIXME
}

export class FormFieldsStoredEvent implements WhitelabelEventWithPayload<FormFieldsStoredEventPayload> {
    type: string;
    static readonly TYPE: string = 'wl-form-fields-stored';
    payload: FormFieldsStoredEventPayload;

    constructor(payload: FormFieldsStoredEventPayload) {
        this.type = FormFieldsStoredEvent.TYPE;
        this.payload = payload;
    }
}
